@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "popinsSemiBold";
  src: url("/public/assets/font/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "popinsRegular";
  src: url("/public/assets/font/Poppins-Regular.ttf");
}
@font-face {
  font-family: "popinsBold";
  src: url("/public/assets/font/Poppins-Bold.ttf");
}
@font-face {
  font-family: "popinsMedium";
  src: url("/public/assets/font/Poppins-Medium.ttf");
}
body {
  background-color: #F4F4F4; /* Apne desired color ka code yahan lagayein */
}
textarea:focus,
select:focus,
input:focus {
  outline: #0381CA solid 2px !important;
  background-color: #F4F4F4 !important;
  color: #1A1A1A !important;
}

[type='text']:focus,
[type='number']:focus,
[type='date']:focus,
select:focus,
[type='password']:focus {
  box-shadow: none !important; /* Removes shadow */
  border-color: transparent !important; /* Resets border color */
  --tw-ring-color: #0381CA !important;
}

[type='text'], [type='password'], [type='number'], [type='date'], select:focus{
  border: none !important;
}

.panelScroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

input[type="checkbox"]:checked {
  background-color: #0381CA !important; /* Green */
  color: white !important; /* White check icon */
}

select {
  border: none !important;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

#gk-cards>div:nth-child(3n) {
  margin-right: 0px !important;
}

.parent {
  overflow: visible; /* Default value */
}

.page-item {
  margin: 0 0.5rem;
}

.page-link {
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 0.375rem;
}

.page-link:hover {
  background-color: #e0e0e0;
}

.active .page-link {
  background-color: #0381CA;
  color: white;
}
